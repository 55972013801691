import React, { useEffect } from 'react'

import { RouteComponentProps } from '@reach/router'
import 'twin.macro'

import CalendlyInlineScheduler from 'components/shared/meeting/calendly-inline-scheduler'
import SEO from 'components/shared/seo'
import fireEvent from 'context/tracking/events'
import Layout from 'mx/layout'
import { MeetingTypeProspect } from 'types/meeting'

const FreeConsultationPage = (
  props: RouteComponentProps<{
    location: {
      state: {
        email: string
        firstName: string
        lastName: string
        phone: string
      }
    }
  }>,
) => {
  let email = '',
    firstName = '',
    lastName = '',
    phone = ''
  if (props.location?.state) {
    email = props.location.state.email
    firstName = props.location.state.firstName
    lastName = props.location.state.lastName
    phone = props.location.state.phone
  }

  useEffect(() => {
    document.body.scrollTo({ top: 0 })
  })

  return (
    <Layout>
      <SEO
        title="Free Consultation Compass Homeowner"
        description="Free FORM Consultation"
        path="/free-consultation-compass-homeowner"
      />
      <CalendlyInlineScheduler
        calendlyUrl={process.env.GATSBY_CALENDLY_COMPASS_HOMEOWNER_URL ?? ''}
        user={{
          email,
          firstName,
          lastName,
          phone,
        }}
        onEventScheduled={() => {
          fireEvent({
            type: 'schedule_completed',
            meetingType: 'FREE_COMPASS_HOMEOWNER',
          })
        }}
        type={MeetingTypeProspect.FREE}
        utmSource="compass"
        utmCampaign="homeowner"
      />
      <br />
      <br />
    </Layout>
  )
}

export default FreeConsultationPage

export const Head = () => (
  <SEO
    title="Free Consultation Compass Homeowner"
    description="Free FORM Consultation"
    path="/free-consultation-compass-homeowner"
  />
)
